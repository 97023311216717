import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

const LoadingHolder = styled.div`
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    position: absolute;
    width:100%;
    z-index: 3;
    right: 0;
    background-color: white;

    span {
    animation: dot ease-in-out 1.5s infinite;
    background-color: grey;
    display: inline-block;
    border-radius: 50%;
    height: 1.5rem;
    margin: 1rem;
    width: 1.5rem;
    }

    span:nth-of-type(2) {
    animation-delay: 0.3s;
    }

    span:nth-of-type(3) {
    animation-delay: 0.6s;
    }

    @keyframes dot {
    0% { background-color: #3d3d3d; transform: scale(1); }
    50% { background-color: #3d3d3d; transform: scale(1.3); }
    100% { background-color: #3d3d3d; transform: scale(1); }
    }
`

const Loader = ({ bgrUrl }) => {
    return (
        <LoadingHolder bgrUrl={bgrUrl}>
            <span></span>
            <span></span>
            <span></span>
        </LoadingHolder>
    )
}

Loader.propTypes = {
    bgrUrl: PropTypes.string,
}

export default Loader